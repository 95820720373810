.login-btn, .btn-style {
    border-radius: 15px;
    border: none;
    background-color: rgb(114, 114, 114);
    color: white;
    padding: 4px 20px;
}

.login-btn:hover, .btn-style:hover {
    text-decoration: none;
    color: white;
    background-color: #a152c3;
}

.login-page {
    text-align: center;
    padding-top: 20vh;
}