.player {
    position: sticky;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: rgb(51, 51, 51);
}

.album-player {
    width: 45px;
    height: 45px;
    left: 40px;
    top: 8px;
    border-radius: 3px;
}

.p-container {
    position: relative;
}

.p-el {
    position: absolute;
}

.info-container {
    left: 100px;
    top: 8px;
}

.play-button {
    left: 49%;
    top: 15px;
    padding-right: 0;
}

.play-button button {
    background-color: transparent;
    border: none;
}

.p-el .MuiSvgIcon-root {
    font-size: 2rem;
}

.song, .artist {
    position: relative;
}
.default-album {
    top: 16px;
    left: 40px;
    color: rgba(196, 196, 196, 0.384);
}
.info-container .song {
    width: 300px;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (max-width: 768px) {
    .play-button {
        left: 80%;
    }
    .info-container .song {
        width: 220px;
    }
}