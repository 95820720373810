.main-nav {
    background-color: rgb(51, 51, 51) !important;
}

.branding {
    padding-left: 8%;
}

.nav-right {
    padding-right: 8%;
}

.navbar-brand {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 4%;
}

.logout:hover {
    color: rgb(126, 126, 126);
}