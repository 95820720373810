.album-art {
    width: 45px;
    height: 45px;
    margin: 2px 0;
    border-radius: 3px;
}

.artist {
    font-size: 11px;
    text-align: left;
    color: rgb(192, 192, 192);
}
.song-link {
    color: white;
}
.song-link:hover {
    color: rgb(192, 192, 192);
}
.song {
    font-size: 12px;
    text-align: left;
    margin-top: 4px;
}

.text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (max-width: 500px) {
    .text {
        max-width: 300px;
    }
}
@media only screen and (max-width: 768px) {
    .song {
        font-size: 14px;
    }
    .artist {
        font-size: 13px;
    }
}

.play-container {
    padding-right: 5px;
    padding-left: 10px;
    padding-top: 10px;
}

.icon {
    border-radius: 100%;
}
.icon:hover {
    background-color: rgb(43, 43, 43);
}
.add {
    color: #ece474;
}

.remove {
    color: #d19fe4;
}

.play, .pause, .block {
    color: rgb(209, 209, 209);
}

