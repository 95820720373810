.btn {
    border-radius: 5px;
    background-color: unset;
    color: white;
}
.jazzify-main {
    min-height: 90vh;
}
body {
    background-color: rgb(59, 59, 59);
}

div, p {
    color: white;
}
.add-all {
    display: block;
    width: 90%;
}
.MuiTabs-indicator {
    background-color: #d19fe4 !important;
}
.filter-container.accordion {
    padding-left: 0;
    padding-right: 0;
    background-color: rgb(59, 59, 59) !important;
    box-shadow: none !important;
    border-bottom: solid;
    border-width: 1px;
    border-color: #d19fe4;
}
.refresh-btn {
    padding: 2px !important;
    margin-left: 10px;
}
.loading-icon {
    margin-top: 50vh;
    color:  #ece474 !important;
}
.remove-all {
    margin-right: 10px;
}
.open-playlist {
    color: #ece474;
    margin-left: 5px;
}
.playlist-row-1 {
    justify-content: flex-end;
    padding-right: 5%;
    padding-bottom: 15px;
}
.playlist-row {
    justify-content: space-between;
    padding-right: 5%;
    padding-left: 5%;
    padding-bottom: 15px;
}
.selector {
    border-radius: 3px;
    color: white;
    background-color: rgb(59, 59, 59);
    max-width: -webkit-fill-available;
}
.add-icon {
    border-radius: 100%;
    margin-bottom: 7px;
}
.add-icon:hover {
    background-color: rgb(114, 114, 114);
}
.show {
    display: flex;
}
.hide {
    display: none !important;
}
.mobile {
    display: none;
}
.desktop {
    display: inline-block;
}
.accordion {
    background-color: rgb(59, 59, 59) !important;
}
.MuiPaper-elevation1 {
    box-shadow: none !important;
}
.MuiIconButton-root {
    color: white !important;
}
@media only screen and (min-width: 768px) {
    .recs-container {
        padding-left: 60px;
    }
    .recs-container, .playlist-container {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .playlist-container {
        padding-right: 60px;
    }
    .add-icon {
        margin-left: 5px;
    }
}

@media only screen and (min-width: 1100px) {
    .recs-container, .playlist-container {
        padding: 40px 60px 40px 60px;
    }
}

@media only screen and (max-width: 768px) {
    .mobile {
        display: inline-block;
        min-width: 100%;
    }
    .desktop {
        display: none;
    }
}

button:focus {
    outline: none;
    box-shadow: none;
}

.MuiAppBar-colorPrimary {
    background-color: rgb(59, 59, 59) !important;
    box-shadow: none !important;
}
.MuiAppBar-root {
    padding-bottom: 20px;
}

.mobile.tabs.show {
    padding-bottom: 20px;
    min-height: 75vh;
}
.clear-all {
    margin-bottom: 7px;
}
#tabpanel-1 {
    min-height: 64vh;
}
.MuiTypography-body1 {
    min-width: 100% !important;
}
.MuiAccordionSummary-root {
    display: inline;
}
.MuiAccordionSummary-content {
    margin-bottom: 0 !important;
}