.github {
    width: 16px !important;
    height: 16px !important;
    margin: 3px;
    margin-left: 8px;
    color: white;
}

.github:hover {
    color: rgb(194, 194, 194);
}

.about-tag {
    background-color: transparent;
    border: none;
    color: white;
}

.about-tag:hover {
    color: rgb(194, 194, 194) !important;
    text-decoration: underline;
}

.footer {
    justify-content: center;
    font-size: 12px;
    padding-bottom: 10px;
}